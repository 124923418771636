import React from "react";
import "./style.css";

const Otviracka = (props) => {
  return (
    <div className="container oteviracka">
      <div className="row ">
        <div className="col otevirackaNadpis">ORDINAČNÍ HODINY</div>
      </div>
      <div className="row">
        <div className="col-4 offset-lg-1">Pondělí</div>
        <div className="col-7 time">7: 00 - 15: 00</div>
      </div>
      <div className="row">
        <div className="col-4 offset-lg-1">Úterý</div>
        <div className="col-7 time">7: 00 - 15: 00</div>
      </div>
      <div className="row">
        <div className="col-4 offset-lg-1">Středa</div>
        <div className="col-7 timeZ">zavřeno</div>
      </div>
      <div className="row">
        <div className="col-4 offset-lg-1">Čtvrtek</div>
        <div className="col-7 time">7: 00 - 15: 00</div>
      </div>
      <div className="row">
        <div className="col-4 offset-lg-1">Pátek</div>
        <div className="col-7 time">7: 00 - 15: 00</div>
      </div>
    </div>
  );
};
export default Otviracka;
