import React, { useState } from "react";

import Image from "./Image.js";

import a from "../img/a.jpg";
import b from "../img/b.jpg";
import c from "../img/c.jpg";
import d from "../img/d.jpg";
import e from "../img/e.jpg";
import f from "../img/f.jpg";
import g from "../img/g.jpg";
import i from "../img/i.jpg";

const fotos = [a, b, c, d, e, f, g, i];

const Galerie = () => {
  const [selectedImage, setSelectedImage] = useState(0);

  const onFotoSelect = index => {
    console.log(index);
    setSelectedImage(index);
  };

  const onClickNext = () => {
    setSelectedImage(selectedImage => {
      if (selectedImage === fotos.length - 1) {
        return (selectedImage = 0);
      } else {
        return selectedImage + 1;
      }
    });
  };

  const onClickPrevious = () => {
    setSelectedImage(selectedImage => {
      if (selectedImage === 0) {
        return (selectedImage = fotos.length - 1);
      } else {
        return selectedImage - 1;
      }
    });
  };

  const mainSrc = fotos[selectedImage];

  const text = "<";

  return (
    <>
      <div className="container galleryMenu">
        <div className="row justify-content-center">
          {fotos.map((foto, index) => {
            return (
              <div key={index} className="col-3  col-lg-1">
                <Image
                  selected={selectedImage === index}
                  index={index}
                  onFotoSelect={onFotoSelect}
                  src={foto}
                  alt={
                    index === 0
                      ? "logo Urologie Alejní"
                      : `Fotografie ordinace č. ${index}`
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="container" style={{ paddingBottom: 30 }}>
        <div className="row justify-content-center align-items-center">
          <div className="col-6 col-md-2">
            <button onClick={onClickPrevious} className="arrows">
              {text}
            </button>
          </div>
          <div className="col-6 col-md-2 arrowHore">
            <button onClick={onClickNext} className="arrows">
              >
            </button>
          </div>

          <div className="col-12 col-md-8">
            <img className="mainFoto" src={mainSrc} alt="foto" />
          </div>
          <div className="col-md-2">
            <button onClick={onClickNext} className="arrows arrowDole">
              >
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Galerie;
