import React from "react";

import Banner from "../img/banner.svg";

const Vizitka = () => {
  return (
    <div>
      <img className="banner" src={Banner} alt="Banner" />
    </div>
  );
};
export default Vizitka;
