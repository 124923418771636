import React from "react";

const Button = props => {
  const onClick = e => {
    const { index } = props;
    props.onSelect(index);
  };

  return (
    <button
      className={props.isSelected ? "buttonActive buttons" : "buttons"}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
};
export default Button;
