import React from "react";

import Galerie from "./Galerie.js";
import About from "./About.js";
import Videoteka from "./Videoteka.js";
import Contact from "./Contact.js";

import "./style.css";

const ONAS = "O nas";
const FOTO = "Fotogalerie";
const VIDEO = "Videoteka";
const KONTAKT = "Kontakty";

const Plocha = props => {
  const { selected } = props;
  let Onas = <About />;
  let Foto = <Galerie />;
  let Video = <Videoteka />;
  let Kontakt = <Contact />;

  return (
    <>
      {selected === FOTO ? (
        <>{Foto}</>
      ) : selected === VIDEO ? (
        <>{Video}</>
      ) : selected === KONTAKT ? (
        <>{Kontakt}</>
      ) : selected === ONAS ? (
        <>{Onas}</>
      ) : (
        <>{Onas}</>
      )}
    </>
  );
};
export default Plocha;
