import React from "react";

const Logo = () => {
  return (
    <div className="container footer">
      <div className="row ">
        <div style={{ textAlign: "left" }} className="col-5 container">
          <div className="row">
            <div className="col-12">IČ: 043 50 006</div>
          </div>
          <div className="row">
            <div className="col-12">IČZ: 58 638 000</div>
          </div>
          <div className="row">
            <div className="col-12" />
          </div>
        </div>
        <div style={{ textAlign: "right" }} className="col-7 container">
          <div className="row">
            <div className="col-12">Urologie Alejní s.r.o.</div>
          </div>
          <div className="row">
            <div className="col-12">Alejní 2795</div>
          </div>
          <div className="row">
            <div className="col-12">415 01 Teplice</div>
          </div>
          <div className="row">
            <div className="col-12">Tel.: <a href="tel:+420775737982">+420 775 737 982</a></div>
          </div>
        </div>
      </div>
      <br />
      <div style={{ textAlign: "left" }} className="row">
        <div className="col-12">
          Urologie Alejní s.r.o. poskytuje zdravotní péči{" "}
          <span style={{ color: "red", fontWeight: 800 }}>plně hrazenou</span> z
          veřejného zdravotního pojištění.
        </div>
      </div>
    </div>
  );
};
export default Logo;
