import React from "react";

import "./App.css";

import Page from "./components/Page.js";

const App = () => {
  return (
    <div className="App">
      <div className="App-header">
        <Page />
      </div>
    </div>
  );
};

export default App;
