import React, { useState } from "react";

import "./style.css";

import Button from "./Button.js";
import Plocha from "./Plocha.js";

const ONAS = "O nás";
const FOTO = "Fotogalerie";
const VIDEO = "Videoteka";
const KONTAKT = "Kontakty";

const Menu = () => {
  const [selected, setSelected] = useState(null);

  const onSelect = index => {
    if (selected === index) {
      setSelected(null);
    } else {
      setSelected(index);
    }
  };
  return (
    <>
      <div className="container shown" style={{ paddingTop: 20 }}>
        <div className="row justify-content-center">
          <div className="col-12">
            <Button
              index={ONAS}
              isSelected={ONAS === selected}
              onSelect={onSelect}
            >
              {ONAS}
            </Button>
          </div>
        </div>
        {selected === ONAS && (
          <div className="row justify-content-center plocha">
            <div className="col-12">
              <Plocha selected={selected} />
            </div>
          </div>
        )}

        <div className="row justify-content-center">
          <div className="col-12">
            <Button
              index={FOTO}
              isSelected={FOTO === selected}
              onSelect={onSelect}
            >
              {FOTO}
            </Button>
          </div>
        </div>
        {selected === FOTO && (
          <div className="row justify-content-center plocha">
            <div className="col-12">
              <Plocha selected={selected} />
            </div>
          </div>
        )}

        <div className="row justify-content-center">
          <div className="col-12 ">
            <Button
              index={VIDEO}
              isSelected={VIDEO === selected}
              onSelect={onSelect}
            >
              Videotéka
            </Button>
          </div>
        </div>
        {selected === VIDEO && (
          <div className="row justify-content-center plocha">
            <div className="col-12">
              <Plocha selected={selected} />
            </div>
          </div>
        )}

        <div className="row justify-content-center">
          <div className="col-12 ">
            <Button
              index={KONTAKT}
              isSelected={KONTAKT === selected}
              onSelect={onSelect}
            >
              {KONTAKT}
            </Button>
          </div>
        </div>
        {selected === KONTAKT && (
          <div className="row justify-content-center plocha">
            <div className="col-12">
              <Plocha selected={selected} />
            </div>
          </div>
        )}
      </div>
      <div className="container shownBig" style={{ paddingTop: 20 }}>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6 col-md-3">
            <Button
              index={ONAS}
              isSelected={ONAS === selected}
              onSelect={onSelect}
            >
              {ONAS}
            </Button>
          </div>
          <div className="col-12 col-sm-6 col-md-3">
            <Button
              index={FOTO}
              isSelected={FOTO === selected}
              onSelect={onSelect}
            >
              {FOTO}
            </Button>
          </div>
          <div className="col-12 col-sm-6 col-md-3">
            <Button
              index={VIDEO}
              isSelected={VIDEO === selected}
              onSelect={onSelect}
            >
              Videotéka
            </Button>
          </div>
          <div className="col-12 col-sm-6 col-md-3">
            <Button
              index={KONTAKT}
              isSelected={KONTAKT === selected}
              onSelect={onSelect}
            >
              {KONTAKT}
            </Button>
          </div>
        </div>
        <div className="row plocha">
          <Plocha selected={selected} />
        </div>
      </div>
    </>
  );
};
export default Menu;
