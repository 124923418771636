import React from "react";

import Logo1 from "../img/logo1.svg";
import Logo2 from "../img/logo2.svg";

const Logo = () => {
  return (
    <div className="container ">
      <div className="row">
        <img className="col-5 logo" src={Logo1} alt="logo" />
        <img className="col-6 col-lg-7 name" src={Logo2} alt="logo" />
      </div>
    </div>
  );
};
export default Logo;
