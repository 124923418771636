import React from "react";

import foto from "../img/foto.jpg";

const About = () => {
  return (
    <>
      <div className="container">
        <div className="aboutHeader">
          <h1 className="aboutHeader">Urologie Alejní s.r.o.</h1>
          <span> je smluvním partnerem těchto zdravotních pojišťoven:</span>
        </div>
        <div className="row">
          <div className="col-11 col-lg-7 offset-lg-1 container">
            <div className="row">
              <div className="col-md-4 codeIns">111 VZP</div>
              <div className="col nameIns">Všeobecná zdravotní pojišťovna</div>
            </div>
            <div className="row">
              <div className="col-md-4 codeIns">207 OZP</div>
              <div className="col nameIns">Oborová zdravotní pojišťovna</div>
            </div>
            <div className="row">
              <div className="col-md-4 codeIns">201 VoZP</div>
              <div className="col nameIns">Vojenská zdravotní pojišťovna</div>
            </div>
            <div className="row">
              <div className="col-md-4 codeIns">213 RBP ZP</div>
              <div className="col nameIns">Revírní bratrská pojišťovna</div>
            </div>
            <div className="row">
              <div className="col-md-4 codeIns">209 ZP Škoda</div>
              <div className="col nameIns">Zdravotní pojišťovna Škoda</div>
            </div>
            <div className="row">
              <div className="col-md-4 codeIns">205 ČPZP</div>
              <div className="col nameIns">
                Česká průmyslová zdravotní pojišťovna
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <img className="foto" src={foto} alt="Mudr. Jiří Černý" />
          </div>
        </div>
      </div>
      <br />

      <div className="text">
        <p>
          &emsp;Urologie Alejní poskytuje komplexní urologickou péči pro dospělé
          a děti, odborné poradenství, prevenci a léčbu nádorových onemocnění.
          Ordinace je vybavena nejmodernější diagnostickou technikou. Provádíme
          ultrazvukové, urodynamické a endoskopické vyšetření na samostatném
          sále. Samozřejmostí je diagnostika onemocnění prostaty u mužů. MUDr.
          Jiří Černý spolupracuje s Urologickým oddělením v Nemocnici v
          Teplicích o.z., KZ a.s., kde provádí operační zákroky v celém rozsahu
          oboru urologie, včetně operací komplikovaných nádorových onemocnění.
        </p>
        <br />
        <p>
          &emsp;Jiří Černý je erudovaný urolog s letitou praxí ve Fakultní
          nemocnici v Motole v Praze, 16 let pracoval jako zástupce primáře
          Urologického oddělení v Nemocnici v Teplicích. Byl vedoucím lékařem
          uroonkologické poradny, je specialista na operativu endoskopickou a
          operativu nádorových onemocnění s praxí ve výuce mediků jako odborný
          asistent 2. Lékařské fakulty Univerzity Karlovy v Praze. Věnuje se
          také postgraduálnímu vzdělávání praktických lékařů, přednáškové a
          publikační činnosti.
        </p>
        <p>
          &emsp;Poskytovaná péče v Urologii Alejní umožňuje odstranění potíží s
          močením u mužů i žen, konzervativní i operační léčbu močových kamenů,
          vyšetření problémů způsobených prostatou včetně zánětů močových cest.
          Samozřejmostí je včasný záchyt karcinomu prostaty, zhoubných nádorů
          ledvin, močového měchýře a varlat. Návštěva urologické ordinace v
          Urologii Alejní nabízí i diskrétní řešení problémů s erekcí u mužů a
          problémů udržení moče u žen.
        </p>
        <br />
      </div>
    </>
  );
};
export default About;
