import React from "react";

const Videoteka = () => {
  return (
    <>
      <div className="container video">
        <div className="row">
          <div className="col-4 date">2.3.2015</div>
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/ivysilani/1148499747-sama-doma/215562220600029/obsah/384382-infekce-mocovych-cest-mudr-jiri-cerny-chat-1-cast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Infekce močových cest (1. část)
            </a>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-4" />
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/ivysilani/1148499747-sama-doma/215562220600029/obsah/384387-infekce-mocovych-cest-mudr-jiri-cerny-chat-2-cast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Infekce močových cest (2. část)
            </a>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-4 date">9.11.2015</div>
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/ivysilani/1148499747-sama-doma/215562220600122/obsah/434291-infekcni-onemocneni-v-urologii-mudr-jiri-cerny-dotazy-1-cast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Infekční urologická onemocnění (ženy - 1.část)
            </a>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-4" />
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/ivysilani/1148499747-sama-doma/215562220600122/obsah/434295-infekcni-onemocneni-v-urologii-mudr-jiri-cerny-dotazy-2-cast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Infekční urologická onemocnění (ženy - 2.část)
            </a>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-4" />
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/ivysilani/1148499747-sama-doma/215562220600122/obsah/434301-infekcni-onemocneni-v-urologii-mudr-jiri-cerny-dotazy-3-cast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Infekční urologická onemocnění (ženy - 3.část)
            </a>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-4 date">12.11.2015</div>
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/ivysilani/1148499747-sama-doma/215562220600125/obsah/434962-benigni-nalezy-v-urologii-mudr-jiri-cerny-dotazy-1-cast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Urologická onemocnění (muži - 1.část)
            </a>
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-4" />
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/ivysilani/1148499747-sama-doma/215562220600125/obsah/434966-benigni-nalezy-v-urologii-mudr-jiri-cerny-dotazy-2-cast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Urologická onemocnění (muži - 2.část)
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-4" />
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/ivysilani/1148499747-sama-doma/215562220600125/obsah/434972-benigni-nalezy-v-urologii-mudr-jiri-cerny-dotazy-3-cast"
              target="_blank"
              rel="noopener noreferrer"
            >
              Urologická onemocnění (muži - 3.část)
            </a>
          </div>
        </div>
        <br />
        <div className="row justify-content-between">
          <div className="col-4 date">27.4.2016</div>
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/porady/1148499747-sama-doma/216562220600062/video/467784"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ledvinové a močové kameny (1. část)
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-4" />
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/porady/1148499747-sama-doma/216562220600062/video/467789"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ledvinové a močové kameny (2. část)
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-4" />
          <div className="col-8 link">
            <a
              href="http://www.ceskatelevize.cz/porady/1148499747-sama-doma/216562220600062/video/467793"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ledvinové a močové kameny (3. část)
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Videoteka;
