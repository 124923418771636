import React from "react";
import "./style.css";

const Otviracka = (props) => {
  return (
    <div className="container otevirackaOut">
      <div className="row justify-content-center">
        <div className="col otevirackaNadpis">ORDINAČNÍ HODINY</div>
      </div>
      <div className="row  justify-content-center">
        <div className="col-4 offset-lg-2">Pondělí</div>
        <div className="col-6 time">7: 00 - 15: 00</div>
      </div>
      <div className="row  justify-content-center">
        <div className="col-4 offset-lg-2">Úterý</div>
        <div className="col-6 time">7: 00 - 15: 00</div>
      </div>
      <div className="row  justify-content-center">
        <div className="col-4 offset-lg-2">Středa</div>
        <div className="col-6 timeZ">zavřeno</div>
      </div>
      <div className="row  justify-content-center">
        <div className="col-4 offset-lg-2">Čtvrtek</div>
        <div className="col-6 time">7: 00 - 15: 00</div>
      </div>
      <div className="row  justify-content-center">
        <div className="col-4 offset-lg-2">Pátek</div>
        <div className="col-6 time">7: 00 - 15: 00</div>
      </div>
    </div>
  );
};
export default Otviracka;
