import React from "react";

import Header from "./Header.js";
import Menu from "./Menu.js";
import Fotter from "./Fotter.js";

import "./style.css";

const Page = () => {
  return (
    <div className="body">
      <div className="container ">
        <div className="row">
          <div className="col-12">
            <Header />
          </div>
          <div className="col-12">
            <Menu />
          </div>
          <div className="col-12">
            <Fotter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
