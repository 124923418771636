import React from "react";

const Button = props => {
  const onClick = e => {
    const { index } = props;
    props.onFotoSelect(index);
  };

  return (
    <img
      className={props.selected ? "imageActive imageMenu" : "imageMenu"}
      src={props.src}
      alt={props.alt}
      onClick={onClick}
    />
  );
};
export default Button;
