import React from "react";

const Contact = () => {
  return (
    <div className="contact">
      <div className="contactHead">Adresa:</div>
      <div className="contactBody"> Urologie Alejní s.r.o.</div>
      <div className="contactBody">Alejní 2795</div>
      <div className="contactBody">415 01 Teplice</div>
      <br />
      <div className="contactHead">Email:</div>
      <div className="contactBody"><a href="mailto:urologiealejni@seznam.cz">urologiealejni@seznam.cz</a></div>
      <br />
      <div className="contactHead">Telefon:</div>
      <div className="contactBody"><a href="tel:+420775737982">+420 775 737 982</a></div>
      <br />
      <div className="contactHead">Web:</div>
      <div className="contactBody">www.urologiealejni.cz</div>
      <br />
      <div className="contactHead">ID datové schránky:</div>
      <div className="contactBody">fkd79sj</div>
      <br />
    </div>
  );
};

export default Contact;
